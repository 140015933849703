import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';

import styles from './FramesBlock.css';
import { Avatar, AvatarTypes } from '../../../../../../atoms/Avatar/Avatar';
import { FramesData } from '../../../../../../constants/FramesImagesList';
import UserService from '../../../../../../services/UserService';
import { setFramesPanelState } from '../../../../../../store/ducks/layout';

export const FramesBlock: FC = () => {
  const { t } = useTranslation();
    const userAvatar = useSelector((state) => state.user?.avatar || state.user?.subscriberAvatar?.avatar || '');
    const userFrame = useSelector((state) => state.user?.subscriberAvatar?.frame || '');
    const backgroundState = useSelector((state) => state.framesPanel?.background || '');
    const [selectedFrame, setSelectedFrame] = useState('');
    const dispatch = useDispatch();
    const userSubscribed = UserService.isUserSubscriber();

    useEffect(() => {
        setSelectedFrame(userFrame);
    }, []);

    const listFrames = FramesData.map((frame) => (
        <Avatar
            key={frame.filename}
            image={userAvatar}
            size={AvatarTypes.MEDIUM_FRAMES}
            onSelect={() => {
                if (userSubscribed) {
                    setSelectedFrame(frame.filename);
                    dispatch(
                        setFramesPanelState({
                            background: backgroundState,
                            frame: frame.filename,
                        })
                    );
                }
            }}
            noFrame={frame.filename === ''}
            frame={frame.filename}
            background={backgroundState}
            selected={userSubscribed && selectedFrame ? selectedFrame === frame.filename : frame.filename === ''}
            noPadlock={!userSubscribed && frame.filename === ''}
            selectable={userSubscribed}
            subscriber={userSubscribed}
            disabled={!userSubscribed}
            focusable
            isFramelist
        />
    ));

    return (
        <div className={styles.container}>
            <div className={styles.title}>{t('AVATARS_PANEL.CHOOSE_FRAME')}</div>
            <div className={styles.frames}>{listFrames}</div>
        </div>
    );
};
