import React, { FC, useEffect, useRef } from 'react';

import classNames from 'classnames';

import { useTranslation } from "react-i18next";
import styles from './AvatarNavigationTabs.css';
import { NavBtnSideMenu } from '../../../../atoms/NavBtnSideMenu/NavBtnSideMenu';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import stylesHeader from '../../../HeaderSideMenu/HeaderSideMenu.css';

interface AvatarNavigationTabsProps {
  activeTab: ProfileRightSideRoutes;
  handleClick: (ProfileRightSideRoutes: ProfileRightSideRoutes) => void;
}

export const AvatarNavigationTabs: FC<AvatarNavigationTabsProps> = React.memo(({ activeTab, handleClick }) => {
  const { t } = useTranslation();
  const tabsContainer = useRef<HTMLDivElement>(null);
  const handleArrowClick = (event: KeyboardEvent) => {
    const { key } = event;

    if (key === 'ArrowLeft') {
      handleClick(ProfileRightSideRoutes.AVATARS_PANEL);
    } else if (key === 'ArrowRight') {
      handleClick(ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL);
    }
  };

  useEffect(() => {
    tabsContainer.current?.addEventListener('keydown', handleArrowClick);

    return () => tabsContainer.current?.removeEventListener('keydown', handleArrowClick);
  }, []);

  const isTabActive = (tabName: string) => tabName === activeTab;

  return (
    <div className={styles.container} role="tablist" aria-label="avatar settings" ref={tabsContainer}>
      <NavBtnSideMenu
        id={ProfileRightSideRoutes.AVATARS_PANEL}
        className={classNames(stylesHeader.navButton, styles.avatarNavBtn, {
          [stylesHeader.activePage]: isTabActive(ProfileRightSideRoutes.AVATARS_PANEL)
        })}
        onClick={() => handleClick(ProfileRightSideRoutes.AVATARS_PANEL)}
        isActive={isTabActive(ProfileRightSideRoutes.AVATARS_PANEL)}
        dataElementDescription="side-menu-avatar-choose"
      >
        {t('NAVIGATION_TABS.SELECT')}
      </NavBtnSideMenu>
      <NavBtnSideMenu
        id={ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL}
        className={classNames(stylesHeader.navButton, styles.avatarNavBtn, {
          [stylesHeader.activePage]: isTabActive(ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL)
        })}
        onClick={() => handleClick(ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL)}
        isActive={isTabActive(ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL)}
        dataElementDescription="side-menu-avatar-customize"
      >
        {t('NAVIGATION_TABS.CUSTOMIZE')}
      </NavBtnSideMenu>
    </div>
  );
});
