import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';

import styles from './BackgroundPicker.css';
import { AvatarBackgroundColors } from '../../../../../../constants/AvatarBackgroundColors';
import { setFramesPanelState } from '../../../../../../store/ducks/layout';

export const BackgroundPicker: FC = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userFrame = useSelector(({ user }) => user?.subscriberAvatar?.frame);
  const avatarBackground = useSelector(({ user }) => user?.avatarBackground || '');
  const frame = useSelector(({ framesPanel }) => framesPanel.frame);
  const [selectedColor, setSelectedColor] = useState<string>('');

  useEffect(() => {
    setSelectedColor(avatarBackground);
    saveStoreData(avatarBackground, frame);
  }, []);

  const saveStoreData = (color: string, frame: string) => {
    dispatch(
      setFramesPanelState({
        background: color,
        frame: frame || userFrame
      })
    );
  };

  return (
    <fieldset className={styles.container}>
      <legend className={styles.title}>{t('AVATARS_PANEL.CHOOSE_BACKGROUND_COLOR')}</legend>
      <div className={styles.colorPicker}>
        {AvatarBackgroundColors.map(({ color, name }) => (
          <Item
            key={color}
            colorRGBA={color}
            colorName={name}
            frame={frame}
            selectedColor={selectedColor}
            setSelectedColor={(v) => {
              setSelectedColor(v);
              saveStoreData(v, frame);
            }}
          />
        ))}
      </div>
    </fieldset>
  );
});

interface ItemProps {
  colorRGBA: string;
  colorName: string;
  frame?: string;
  selectedColor: string;
  setSelectedColor: (value: string) => void;
}

const Item = ({ colorRGBA, colorName, selectedColor, setSelectedColor }: ItemProps) => {
  const isSelectedColor = selectedColor === colorRGBA;

  return (
    <div key={colorName} className={styles.colorItemWrapper}>
      <input
        type="radio"
        name="color"
        id={colorName}
        value={colorRGBA}
        className={styles.screenReaderOnly}
        checked={isSelectedColor}
        onChange={() => {
          setSelectedColor(colorRGBA);
        }}
      />
      <label
        htmlFor={colorName}
        style={{ backgroundColor: colorRGBA }}
        className={classNames(styles.colorItem, { [styles.selected]: isSelectedColor })}
      >
        <span className={styles.screenReaderOnly}>{colorName}</span>
      </label>
      <div
        style={{ borderColor: isSelectedColor ? colorRGBA : 'transparent' }}
        className={classNames({ [styles.hoverItem]: isSelectedColor })}
      />
    </div>
  );
};
